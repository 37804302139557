import React, { useCallback, useMemo } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import SEO from '../components/common/seo';
// import { TagsSectionFooter } from '../components/layout/TagsSectionFooter';
import { getCategoryColor, useAppContext } from '../components/context/AppContext';
import { HandwrittenTitle } from '../components/common/HandwrittenTitle';
import { getFeaturedImageSrc, getPostCategory } from '../utils/data';
import { ExploreItem } from '../components/layout/ExploreItem';
import { MuteButton } from '../components/common/MuteButton';
import { Icon } from '../components/common/Icon/Icon';
import styles from './Explore.module.scss';

export const pageQuery = graphql`
  query {
    allCategories: allWpCategory(
      sort: { order: ASC, fields: categoryExtraFields___order }
      filter: { name: { ne: "Uncategorised" } }
    ) {
      edges {
        node {
          slug
          name
          description
          categoryExtraFields {
            comingSoon
          }
        }
      }
    }

    allPosts: allWpPost {
      edges {
        node {
          title
          slug
          categories {
            nodes {
              slug
              categoryExtraFields {
                comingSoon
              }
            }
          }

          mp4Rollover {
            mp4Rollover {
              altText
              localFile {
                publicURL
              }
            }
            rolloverAudio {
              localFile {
                publicURL
              }
            }
          }
          featuredImage {
            node {
              localFile {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 1280) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const ExplorePage = ({ data }) => {
  const { muteInteractions } = useAppContext();

  const categories = data.allCategories.edges;
  const posts = useMemo(() => {
    return data.allPosts.edges.map(({ node: post }) => {
      return {
        node: {
          ...post,
          effects: {
            scale: Math.random() * 0.3 + 0.7,
            rotate: Math.random() * 20 - 10,
          },
        },
      };
    });
  }, [data.allPosts]);

  const onJump = useCallback(() => {
    window.scrollBy({
      top:
        document.querySelector(`.${styles.canvasLayout}`).getBoundingClientRect().top -
        document.querySelector('header').getBoundingClientRect().height,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <>
      <SEO title="Explore" />
      <Helmet
        bodyAttributes={{
          class: getCategoryColor(),
        }}
      />

      <div className={styles.canvas}>
        <div className={styles.canvasTitle}>
          <HandwrittenTitle className={styles.canvasTitleHandwriting} title="Explore" />

          <p>See and hear more about our experiences – choose a subject to explore</p>

          <button className={styles.jump} onClick={onJump} aria-label="previous">
            <Icon size="free" name="arrow-drawn-down" />
          </button>
        </div>

        <div className={styles.canvasLayout}>
          <MuteButton className={styles.mute} size="free" />
          {categories &&
            categories.map((category, index) => (
              <div
                className={classNames(styles.canvasLayoutTitle, category.node.slug)}
                key={`category-${category.node.slug}`}
              >
                <HandwrittenTitle
                  svgClassName={styles.canvasLayoutTitleSvg}
                  className={styles.canvasLayoutTitleEl}
                  alt
                  title={category.node.name}
                  responsive
                />
              </div>
            ))}

          <div className={styles.canvasLayoutArea}>
            {categories &&
              categories.map((category, index) => (
                <div
                  className={classNames(styles.canvasDeviceTitle, category.node.slug)}
                  key={`category-${category.node.slug}`}
                >
                  <HandwrittenTitle
                    // svgClassName={styles.canvasLayoutTitleSvg}
                    className={styles.canvasDeviceTitleEl}
                    narrow
                    title={category.node.name}
                    responsive
                  />
                </div>
              ))}
            {posts.map(({ node: post }, index) => {
              const { slug: categorySlug, comingSoon } = getPostCategory(post.categories);
              return (
                <div
                  key={post.slug}
                  className={classNames(styles.canvasPost, categorySlug, post.slug)}
                  style={{
                    transform: `scale(${post.effects.scale}) rotate(${post.effects.rotate}deg)`,
                  }}
                >
                  <ExploreItem
                    to={`/${post.slug}`}
                    comingSoon={!!comingSoon}
                    title={post.title}
                    image={getFeaturedImageSrc(post.featuredImage)}
                    audio={!muteInteractions && post.mp4Rollover?.rolloverAudio?.localFile?.publicURL}
                    video={post.mp4Rollover?.mp4Rollover?.localFile?.publicURL}
                    itemIndex={index}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* <Wrapper className={styles.exploreDevice} wrapped border padded>
        <TextSvg
          className={classNames(styles.label, styles.bottom)}
          tagEl="button"
          svg={LabelSvg}
          onClick={() => toggleEvents(true)}
        >
          Events
        </TextSvg>

        <div>
          <HandwrittenTitle className={styles.title} title="Explore" />

          <p>See and hear more about our experiences – choose a subject to explore</p>
          <CategoriesListing large categories={categories} className={styles.exploreCategories} />
        </div>
      </Wrapper> */}
    </>
  );
};
export default ExplorePage;
