import React, { useMemo, useRef, useCallback } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import { ReactComponent as Square001 } from '../../common/TextSvg/assets/Square001.svg';
import { ReactComponent as Square002 } from '../../common/TextSvg/assets/Square002.svg';
import { ReactComponent as Square003 } from '../../common/TextSvg/assets/Square003.svg';
import { ReactComponent as Square004 } from '../../common/TextSvg/assets/Square004.svg';
import { ReactComponent as Square005 } from '../../common/TextSvg/assets/Square005.svg';
import { ReactComponent as Square006 } from '../../common/TextSvg/assets/Square006.svg';
import { ReactComponent as ComingSoonSvg } from '../../../images/svg/coming-soon.svg';

import styles from './ExploreItem.module.scss';

const SQUARES = [Square001, Square002, Square003, Square004, Square005, Square006];

const ExploreItem = ({ to, className, image, title, video, audio, itemIndex = 0, comingSoon, ...props }) => {
  // const videoRef = useRef(null);
  const audioRef = useRef(null);
  const SvgEl = useMemo(() => SQUARES[itemIndex % SQUARES.length], [itemIndex]);

  const clip = useMemo(() => {
    return typeof itemIndex !== 'undefined' ? { '--clip-name': `url(#square00${(itemIndex % 6) + 1}Clip` } : {};
  }, [itemIndex]);

  const cls = classNames(styles.outer, { [styles.comingSoon]: comingSoon }, className);

  const onMouseEnter = useCallback(() => {
    const { matches } = window.matchMedia('hover: none');

    try {
      if (!matches) {
        // if (videoRef.current) {
        //   videoRef.current.play();
        // }
        if (audioRef.current) {
          audioRef.current.play();
        }
      }
    } catch {
      // nothing
    }
  }, []);

  const onMouseLeave = useCallback(() => {
    // if (videoRef.current) {
    //   videoRef.current.pause();
    // }
    if (audioRef.current) {
      audioRef.current.pause();
    }
  }, []);

  const inner = (
    <>
      <SvgEl className={styles.svg} />
      {comingSoon && <ComingSoonSvg className={styles.comingSoonSvg} />}
      {!comingSoon && image && <img className={styles.media} src={image} alt="" />}
      {/* {!comingSoon && video && <video ref={videoRef} muted loop className={styles.media} src={video} />} */}
      {!comingSoon && audio && <audio ref={audioRef} loop className={styles.audio} src={audio} />}
      {!comingSoon && (
        <span className={styles.content}>
          <span className={styles.title}>{title}</span>
        </span>
      )}
    </>
  );

  if (comingSoon) {
    return (
      <div className={cls} style={clip} {...props}>
        {inner}
      </div>
    );
  }

  return (
    <Link className={cls} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} to={to} style={clip} {...props}>
      {inner}
    </Link>
  );
};

export { ExploreItem };
