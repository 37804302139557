import React from 'react';
import classNames from 'classnames';

import { useAppContext } from '../../context/AppContext';
import { Icon } from '../Icon/Icon';
import styles from './MuteButton.module.scss';

const MuteButton = ({ className, size = 'medium' }) => {
  const { muteInteractions, toggleMute } = useAppContext();

  return (
    <button className={classNames(styles.btn, className)} onClick={() => toggleMute()}>
      <Icon name={muteInteractions ? 'volume-off' : 'volume-on'} size={size} />
    </button>
  );
};

export { MuteButton };
